import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box } from "../../components/Core";
import { device } from "../../utils";

import imgBorderShape from "../../assets/image/png/cta-border-shape.png";
import imgCTA from "../../assets/image/png/cta-shape.png";
import {Link} from "gatsby";

const ShapeBorder = styled(Box)`
  position: absolute;
  display: none;

  @media ${device.md} {
    display: block;
    top: 4%;
    right: -13%;
  }
  @media ${device.xl} {
    right: -2%;
  }
`;

const ShapeCTA = styled(Box)`
  position: absolute;
  bottom: 0;
  left: -5%;
`;

const CTA = () => (
  <>
    <Section bg="light" className="position-relative">
      <Container>
        <Row>
          <Col lg="9" md="8">
            <div className="py-4">
              <Title color="dark">
                Same-Day & Low-Cost  <br />
                Get Your FREE Quote Today
              </Title>
            </div>
          </Col>
          <Col
            lg="3"
            md="4"
            className="d-flex align-items-center justify-content-lg-end mt-4 mt-md-0"
          >
            <div>
              <a href='tel: 0422 727 511'>
              <Button bg='#0e9ae5' color="white" borderRadius="500px" mb={3} className='w-100'>Call Now</Button>

              </a>

              <Link to='/#quote-form'>
              <Button bg='#0e9ae5' color="white" borderRadius="500px" className='w-100'>Get Your FREE Quote</Button>

              </Link>


            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default CTA;
