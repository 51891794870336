import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import { Section, Box, Text, Button, Title } from "../../components/Core";
import Rating from "../../components/Rating";

const ContentCard = ({
  className,
  numStar = 5,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Box className={`${className}`} {...rest} py={4} borderRadius="25px" bg="light" p={3} mb={3}>
    <Rating
      numStar={numStar}
      color="warning"
      pb={2}
      css={`
        font-size: 21px;
      `}
    />

    <Text color="dark" mt={3} mb={3} className="font-italic">
      {children}
    </Text>
    <Box className={`d-flex justify-content-between mt-auto`} pt={2}>
      <div className="flex-grow-1">
        <Text
          color="darkShade"
          lineHeight="1"
        >
          
          <Text>

          </Text>
          <Text variant='small' color='dark' className='pt-2 pl-2'> 
          <i className="fab fa-google mr-3"></i> {name}

          </Text>

        </Text>
      </div>
    </Box>
  </Box>
);

const Reviews = () => {
  return (
    <>
      <Section bg="#0e9ae5" id='testimonials'>
        <Container>
          <Title color='white' className='text-center' py={2}>
          Our Testimonials
          </Title>
          <Row className="justify-content-center mt-3"                 data-aos="zoom-left"
                data-aos-duration="500"
                data-aos-once="true"
                data-aos-delay="250"
>
            <Col md="6" className="mb-md-5 mb-lg-0">
              <ContentCard name="Eliza McKendry" numStar={5}>
                "The service was 10/10. So fast to pick up (5 mins) and was so efficient with removing the garbage. Great prices. Will 100% be using again! Recommend to everyone."
              </ContentCard>
              <ContentCard name="Ali Nelson" numStar={5}>
                "This service was great, I put in a quote request online this morning (for garden waste and other misc item
collection) and had gotten a response within half an hour and then Simon came just a few hours later! It was super efficient and Simon was friendly and professional. Was very happy with the price and service and would use this business again for sure.

              </ContentCard>

            </Col>
            <Col md="6" className="mb-md-5 mb-lg-0">
              <ContentCard name="Matthew Storey" numStar={5}>
                "Great bloke, kept in the loop all day so I knew when he was going to turn up. It was raining and that still didn’t stop him from doing the job. Highly recommend"
              </ContentCard>
              <ContentCard name="Bronte" numStar={5} >
                "I was very very pleased with the not only the removal service provided but also the professionalism and friendliness. Dump your junks team was prompt in providing an easy over the phone quote and on time service.
I had a wonderful customer experience and would highly suggest this company to my friends and family for future services."
              </ContentCard>

            </Col>
          </Row>
          <a href='https://g.page/sydneyjunkremoval' target="_blank">
          <Text className='text-center text-white' pt={5}>
            
              Read More Reviews

          </Text>


          </a>
        </Container>
      </Section>
    </>
  );
};

export default Reviews;
