import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Text } from "../../components/Core";
import {Link} from "gatsby";

import imgContent from "../../assets/client-images/dyj_circle_items.png";


import imgContentWebP from "../../assets/client-images/dyj_circle_items.webp";

const SectionStyled = styled(Section)`
`;

const Content3 = () => (
  <>
    <SectionStyled bg="#0e9ae5">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col lg="5" className="offset-lg-1 order-lg-2 mb-5 mb-lg-0">
            <div>
              <div
                data-aos="zoom-out"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <picture>
                      <source srcset={imgContentWebP} type="image/webp" />
                      <img src={imgContent} alt="" className="img-fluid" />
                </picture>

              </div>
            </div>
          </Col>
          <Col
            md="10"
            lg="6"
            className="order-lg-1"
            data-aos="fade-right"
            data-aos-duration="750"
            data-aos-once="true"
          >
            <div>
              <Title color="light">
              Serving Greater Sydney
              </Title>
              <Text color="light">
              We pride ourselves in offering a professional service to the highest standard without burning a hole in your wallet - Offering affordable pricing as well as a sustainable service, we donate anything of value before recycling the rest - you can trust that choosing us will not only benefit your pockets, but your community and environment as well.


              </Text>
              <div className="mt-5">
              <Link to='/#quote-form'>

                <Button bg="white" color='black' borderRadius="500px">Get Your FREE Quote</Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Content3;
