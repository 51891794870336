import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Badge } from "../../components/Core";

import imgShape from "../../assets/client-images/5star_google_badge.png";
import imgContent from "../../assets/client-images/dyj-before-after.png";

import imgShapeWebp from "../../assets/client-images/5star_google_badge.webp";
import imgContentWebp from "../../assets/client-images/dyj-before-after.webp";


import { rgba } from "polished";
import { device } from "../../utils";


const SectionStyled = styled(Section)`
`;

const ShapeBox = styled(Box)`
  position: absolute;
  top: -70px;
  left: -20px;
  z-index: 2;
`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 0;
  padding-left: 0;

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: black;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 35px;
    display: flex;
    margin-top: 10px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      min-width: 30px;
      height: 30px;
      background-color: #0e9ae5;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: white;
      position: relative;
      top: 2px;
      margin-right: 13px;
    }
  }
`;


const Content1 = () => (
  <>
    <SectionStyled bg="light" id='services'>
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col lg="6" className="my-5 my-lg-0">
            <div className="">
              <Box
                className=" "
              >
                <picture>
                      <source srcset={imgContentWebp} type="image/webp" />
                      <img src={imgContent} alt="" className="img-fluid" />
                </picture>

              </Box>
              <ShapeBox
                className=""
                data-aos="zoom-in"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                                <picture>
                                <source srcset={imgShapeWebp} type="image/webp" />

                <img
                  src={imgShape}
                  alt=""
                  className="img-fluid"
                  css={`
                    opacity: 1;
                  `}
                />
                                </picture>

              </ShapeBox>
            </div>
          </Col>
          <Col
            md="10"
            lg="6"
            className="mt-3 mt-lg-0"
            data-aos="fade-right"
            data-aos-duration="750"
            data-aos-once="true"
          >
            <Badge bg="#0e9ae5">See Why We Only Get 5 Star Reviews</Badge>
            <div className="mt-4">
              <Title color="dark">
              You name it, we'll take it!
              </Title>
              <Text color="darkShade">
               
                <Row>
                  <Col md={6}>
                  <ULStyled className=''>
                      <li>Furniture & Goods</li>
                      <li>Commercial Furniture</li>
                      <li>Fridges</li>
                      <li>Electronic devices</li>
                      <li>Demolition</li>
                      <li>Food waste</li>
                      <li>Hot water tanks</li>
                    </ULStyled>

                  </Col>
                  <Col md={6}>
                  <ULStyled className='d-none d-md-block'>
                      <li>Green Waste</li>
                      <li>Concrete</li>
                      <li>Timber</li>
                      <li>Bricks</li>
                      <li>Tiles</li>
                      <li>Pot plants</li>
                    </ULStyled>

                  </Col>
                </Row>

              </Text>
            </div>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Content1;
