import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import {Link} from "gatsby";

import { Section, Box, Text, Title, Button } from "../../components/Core";

const FeatureCard = ({
  color = "primary",
  iconName,
  title,
  children,
  ...rest
}) => (
  <Box pt="10px" px="30px" borderRadius={10} mb={2} {...rest}>
    <Box
      size={69}
      borderRadius="50%"
      color={color}
      fontSize="28px"
      className="d-flex justify-content-center align-items-center mx-auto"
      css={`
        background-color: ${({ theme, color }) =>
          rgba(theme.colors[color], 0.1)};
      `}
    >
      <i className={`fas ${iconName}`}></i>
    </Box>
    <div className="text-center">
      <Text
        color="dark"
        as="h3"
        fontSize={4}
        fontWeight={500}
        letterSpacing={-0.75}
        my={3}
      >
        {title}
      </Text>
      <Text fontSize={2} lineHeight={1.75} color="dark">
        {children}
      </Text>
    </div>
  </Box>
);

const Feature = () => (
  <>
    {/* <!-- Feature section --> */}
    <Section className="position-relative pt-5" bg="light">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="secondary"
              iconName="fa-clock"
              title="Same-Day"
            >
              Enjoy the convenience of same day delivery Sydney wide.
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="success"
              iconName="fa-recycle"
              title="Eco-Friendly"
            >
              We are all for the environment and recycle anything we can!
            </FeatureCard>
          </Col>

          <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-5">
            <FeatureCard
              color="warning"
              iconName="fa-donate"
              title="Donation"
            >
              Before recycling we look to help the community by donating to the Salvation Army.
            </FeatureCard>
          </Col>

        </Row>
        <Row className='pt-5'>
          <Title className='text-center w-100'>
            Our Service Area
          </Title>
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d424141.69764626387!2d150.931975!3d-33.848244!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b129838f39a743f%3A0x3017d681632a850!2sSydney%20NSW!5e0!3m2!1sen!2sau!4v1588647994789!5m2!1sen!2sau" width="100%" height="450" frameborder="0" style={{border: "0px"}} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </Row>
        <Row>
          <Col md='12' className='text-center'>
          <a href='tel: 0422 727 511'>
              <Button
                mt={4}
                bg='#0e9ae5'
                borderRadius="500px"
                padding="0.5rem 1rem"
              >
                0422 727 511
              </Button>

              </a>

          </Col>
          <Col md='12' className='text-center'>
              <Link to='/#quote-form' >

              <Button
                mt={4}
                bg='#0e9ae5'
                padding="0.5rem 1rem"
                borderRadius="500px"
              >
                Get Your Free Quote
              </Button>
              </Link>

          </Col>

        </Row>
      </Container>
    </Section>
  </>
);

export default Feature;
